/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable default-case */
/* eslint-disable import/no-mutable-exports */
import DapiIntegration from './dapi-integration';
import MraidIntegration from './mraid-integration';
import MolocoIntegration from './moloco-integration';
import MintegralIntegration from './mintegral-integration';
import GoogleAdsIntegration from './googleAds-integration';
import TiktokIntegration from './tiktok-integration';
import MetaIntegration from './meta-integration';

export class EventEmitter {
  private _events: { [event: string]: Function[] } = {};

  public emit(event: string) {
    if (this._events[event]) {
      this._events[event].forEach((e) => e());
    }
  }

  public addListener(event: string, callback: Function) {
    if (!this._events[event]) {
      this._events[event] = [callback];
    } else if (!this._events[event].includes(callback)) {
      this._events[event].push(callback);
    }
  }

  public removeListener(event: string, callback: Function) {
    if (this._events[event]) {
      const index = this._events[event].indexOf(callback);
      if (index >= 0) {
        this._events[event].splice(index, 1);
      }
    }
  }
}

export interface INetworkIntegration {
  name: string;
  preloader: boolean;
  MP_META: string;
  MP_SCRIPT: string;
  _events: EventEmitter;

  addListener: (event: string, callback: () => void) => void;
  removeListener: (event: string, callback: () => void) => void;

  onWindowLoad: () => void;
  onReady: () => void;
  onResize: () => void;
  onViewChange: (param: any) => void;
  onVolumeChange: () => void;

  install: () => void;
  gameEnd: () => void;
  gameReady: () => void;
  sendMsg: (msg: string) => void;

  getScreenSize: () => { width: number; height: number; };
  getAudioVolume: () => number;
}

export const Events = {
  gameStart: 'gameStart',
  gamePause: 'gamePause',
  gamePlay: 'gamePlay',
  screenResize: 'screenResize',
  soundChange: 'soundChange',
  windowLoaded: 'windowLoaded',
};

export class AppLovinIntegration extends MraidIntegration {
  public get name() { return 'applovin'; }
}

export class UnityAdsIntegration extends MraidIntegration {
  public get name() { return 'unityads'; }
}

export class IronSourceIntegration extends MraidIntegration {
  public get name() { return 'ironsource'; }
}

export class DigitalTurbineIntegration extends MraidIntegration {
  public get name() { return 'digitalturbine'; }
}

export class YsoNetworkIntegration extends MraidIntegration {
  public get name() { return 'ysonetwork'; }
}

export function getNetwork() {
  return NETWORK;
}

export function setNetwork(network: string) {
  switch (network) {
    case 'mintegral':
      NETWORK = new MintegralIntegration();
      break;
    case 'applovin':
      NETWORK = new AppLovinIntegration();
      break;
    case 'unityads':
      NETWORK = new UnityAdsIntegration();
      break;
    case 'digitalturbine':
      NETWORK = new DigitalTurbineIntegration();
      break;
    case 'ironsource':
      NETWORK = new IronSourceIntegration();
      break;
    case 'ysonetwork':
      NETWORK = new YsoNetworkIntegration();
      break;
    case 'googleads':
      NETWORK = new GoogleAdsIntegration();
      break;
    case 'meta':
      NETWORK = new MetaIntegration();
      break;
    case 'tiktok':
      NETWORK = new TiktokIntegration();
      break;
    case 'moloco_html':
      NETWORK = new MolocoIntegration();
      break;
    case 'moloco_txt':
      NETWORK = new MolocoIntegration();
      break;
  }
}

let NETWORK: INetworkIntegration = new MintegralIntegration();

export default NETWORK;
